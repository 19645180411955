import * as React  from 'react';
import '../styles.css';
const Work = () => {
    return(
      <>
        <div className="header">
          <h1 className="h1">This is the Work Page</h1>
       </div>

      <div className="main">
        <p className="p">This is the main content of the Work Page</p>
      </div>

      <div className="footer"><h2>Footer</h2></div>
      <div className="reachable"><h2>Social Media</h2></div>
      </>

    );
}

export default Work;